.root {
  width: calc(100% - 20px);
  max-width: 1000px;
  padding: 20px 10px;
  display: flex;
}

.image {
  max-width: 275px;
  min-width: 250px;
  flex: 1;
}

.loading-bar {
  width: 100%;
  min-width: 200px;
  margin: 0px 10px;
}

.tags-container {
  flex: 1;
  display: flex;

  padding: 10px 0px 0px 10px;
  align-items: flex-end;
  justify-content: baseline;
}
